import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Harium",
  keyword:
    "games, apps, VR, AR, Virtual Reality, webxr",
  desc: "Play Virtual Reality games in your browser.",
};

export default Meta;
